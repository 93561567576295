import { notification } from 'antd';

const createNotification = (type, message, description) => {
  if (typeof message === 'string') {
    notification[type]({
      message,
      description,
    });
  }
};
export default createNotification;
